import { ComboType } from "../constants/combo.constants";
import { createHttp } from "../utils/http-common";

const controller = "product";

const getProductByIdAsync = (productId) => {
  const http = createHttp();
  return http.get(`/${controller}/get-product-by-id?productId=${productId}`);
};

const getProductsByCategoryIdAsync = (categoryId, keySearch) => {
  const http = createHttp();
  return http.get(`/${controller}/get-products-by-category-id?categoryId=${categoryId}&keySearch=${keySearch}`);
};

const getProductsByCategoryIdsAsync = (
  categoryIds,
  keySearch,
  isCheckAllCategory = false,
  platformId,
  branchId = "",
) => {
  const http = createHttp();
  return http.get(
    `/${controller}/get-products-by-category-ids?categoryIds=${categoryIds}&keySearch=${keySearch}&isCheckAllCategory=${isCheckAllCategory}&branchId=${branchId}&platformId=${platformId}`,
  );
};

const getAllProductsActiveAsync = () => {
  const http = createHttp();
  return http.get(`/${controller}/get-products-active`);
};

const getAllProductByStoreThemeIdAsync = (values) => {
  const http = createHttp();
  return http.post(`/${controller}/get-all-product-by-store-theme-id`, values);
};

const getAllProductCategoriesAsync = () => {
  const http = createHttp();
  return http.get(`/${controller}/get-all-product-categories`);
};

const getProductCategoriesStoreTheme = (branchId = "") => {
  const http = createHttp();
  return http.get(`/${controller}/get-product-category-store-theme?branchId=${branchId}`);
};

const getProductsStoreTheme = (categoryId = "", branchId = "", limit = "", productId = "") => {
  const http = createHttp();
  return http.get(
    `/${controller}/get-products-store-theme?categoryId=${categoryId}&branchId=${branchId}&limit=${limit}&&productId=${productId}`,
  );
};

const getProductsStoreScrollSpy = (categoryId = "", branchId = "") => {
  const http = createHttp();
  return http.get(`/${controller}/get-products-store-scroll-spy?categoryId=${categoryId}&branchId=${branchId}`);
};
const getCombosStoreScrollSpy = (storeId = "", branchId = "") => {
  const http = createHttp();
  return http.get(`/${controller}/get-combos-store-scroll-spy?storeId=${storeId}&branchId=${branchId}`);
};
const getCombosStoreTheme = (branchId = "") => {
  const http = createHttp();
  return http.get(`/${controller}/get-combos-store-theme?branchId=${branchId}`);
};

const getTheFirstProductAsync = () => {
  const http = createHttp();
  return http.get(`/${controller}/get-the-first-product`);
};

const getProductDetailByIdAsync = (productId, platformId, branchId = "") => {
  const http = createHttp();
  return http.get(
    `/${controller}/get-product-detail-by-id-store-theme?productId=${productId}&branchId=${branchId}&platformId=${platformId}`,
  );
};
const getToppingsByProductIdAsync = (productId) => {
  const http = createHttp();
  return http.get(`/${controller}/get-toppings-by-product-id-store-theme/${productId}`);
};

const calculatingPriceOfTheProduct = (data) => {
  const http = createHttp();
  return http.post(`/${controller}/calculate-price-of-product`, data);
};

const getProductCategoriesActivated = () => {
  const http = createHttp();
  return http.get(`/${controller}/get-all-product-categories`);
};

const getProductCartItemAsync = (data) => {
  const http = createHttp();
  return http.post(`/${controller}/calculate-product-cart-item`, data);
};

const checkProductInBranchAsync = (productId, branchId) => {
  const http = createHttp();
  return http.get(`/${controller}/check-product-in-branch?productId=${productId}&branchId=${branchId ?? null}`);
};
const verifyProductStoreThemeAsync = (
  productId,
  platformId,
  branchId = "",
  promotionId = "",
  promotionType = 0,
  productPriceId = "",
  isApplyFlashSale = true,
) => {
  const http = createHttp();
  return http.get(
    `/${controller}/verify-product-store-theme?id=${productId}&branchId=${branchId}&promotionId=${promotionId}&promotionType=${promotionType}&productPriceId=${productPriceId}&isApplyFlashSale=${isApplyFlashSale}&platformId=${platformId}`,
  );
};

const getProductsAsync = (data) => {
  const http = createHttp();
  const { categoryId = "", branchId = "", platformId = "", pageNumber = 0, pageSize = 0 } = data;
  return http.get(
    `/${controller}?categoryId=${categoryId}&branchId=${branchId}&platformId=${platformId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );
};

const mappingOptions = (productOptions) => {
  var options = [];
  productOptions?.map((item) => {
    const option = {
      id: item.id,
      name: item.name,
      optionLevelId: item?.optionLevels.find((i) => i.isSetDefault === true).id,
      isSetDefault: true,
      optionLevelName: item?.optionLevels.find((i) => i.isSetDefault === true)?.name,
    };
    options.push(option);
  });
  return options;
};

const mappingToppings = (productToppings) => {
  var toppings = [];
  productToppings?.map((item) => {
    const topping = {
      id: item.toppingId,
      name: item.name,
      originalPrice: item.priceValue,
      priceValue: item.priceValue,
      quantity: item.quantity,
    };
    toppings.push(topping);
  });
  return toppings;
};

const mappingProducts = (comboProductPrices) => {
  var products = [];
  comboProductPrices?.map((item) => {
    const product = {
      id: item.productPrice.productId,
      name: item.productPrice.product.name + item.productPrice.priceName,
      productPrice: {
        id: item.productPriceId,
      },
      thumbnail: item.productPrice.product.thumbnail,
      options: mappingOptions(item.productPrice.product.productOptions),
      toppings: mappingToppings(item.productPrice.product.productToppings),
    };
    products.push(product);
  });
  return products;
};

const mappingComboProductSelect = (product) => {
  const isComboFlexible = product.comboTypeId === ComboType.FLEXIBLE;
  let productName = product.name;
  let productId = product.id;
  if (isComboFlexible) {
    productName = product.comboPricingName;
    productId = product.comboId;
  }

  const productData = {
    comboTypeId: product?.comboTypeId,
    dataDetails: {
      comboProductPrices: product?.comboProductPrices,
      comboTypeId: product?.comboTypeId,
      description: product.description,
      id: productId,
      isCombo: product.isCombo,
      name: productName,
      notes: "",
      originalPrice: product.originalPrice,
      quantity: 1,
      sellingPrice: product.sellingPrice,
      percentDiscount: product.percentDiscount,
      thumbnail: product.thumbnail,
      comboPricingId: product.comboPricingId,
      comboPricingName: product.comboPricingName,
    },
    id: productId,
    name: productName,
    isCombo: product.isCombo,
    notes: "",
    originalPrice: product.originalPrice,
    quantity: 1,
    sellingPrice: product.sellingPrice,
    thumbnail: product.thumbnail,
    percentDiscount: product.percentDiscount,
    totalOfToppingPrice: 0,
    products: mappingProducts(product?.comboProductPrices),
  };
  return productData;
};

const mappingProductSelect = (product, productTemp) => {
  const productData = {
    id: product.id,
    name: product.name,
    isCombo: product.isCombo,
    isFlashSale: product.isFlashSale,
    isPromotionProductCategory: product.isPromotionProductCategory,
    notes: "",
    quantity: 1,
    thumbnail: product.thumbnail,
    dataDetails: {
      product: {
        productDetail: product.productDetail,
        productToppings: product.productToppings,
        promotions: product.promotions,
      },
    },
    options: mappingOptions(product.productDetail.productOptions),
    productPrice: productTemp?.isFlashSale
      ? product?.productDetail?.productPrices[
          product?.productDetail?.productPrices?.findIndex((x) => x.id === productTemp?.productPriceId)
        ]
      : product?.productDetail?.productPrices[0],
    toppings: mappingToppings(product.productToppings),
  };
  return productData;
};

const productDataService = {
  getProductByIdAsync,
  getProductsByCategoryIdAsync,
  getProductsByCategoryIdsAsync,
  getAllProductsActiveAsync,
  getAllProductByStoreThemeIdAsync,
  getAllProductCategoriesAsync,
  getProductCategoriesStoreTheme,
  getProductsStoreTheme,
  getCombosStoreTheme,
  getTheFirstProductAsync,
  getProductDetailByIdAsync,
  getToppingsByProductIdAsync,
  calculatingPriceOfTheProduct,
  getProductCategoriesActivated,
  getProductCartItemAsync,
  checkProductInBranchAsync,
  getProductsStoreScrollSpy,
  getCombosStoreScrollSpy,
  verifyProductStoreThemeAsync,
  getProductsAsync,
  mappingComboProductSelect,
  mappingProductSelect,
};
export default productDataService;
