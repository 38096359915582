import defaultFlashSaleProductImg from "../../../assets/images/default-flash-sale-product.png";

export const flashSaleDefaultLocal = {
  flashSaleHasEnded: {
    id: "be618c75-0216-4320-b09d-1817d5272887",
    name: "r4a",
    startDate: "2023-03-18T03:36:00",
    endDate: "2023-03-18T03:36:00",
    flashSaleProducts: [
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
    ],
  },
  flashSaleIsHappening: {
    id: "be618c75-0216-4320-b09d-1817d5272887",
    name: "r4a",
    startDate: "2023-04-18T03:36:00",
    endDate: "2099-04-18T16:00:00",
    flashSaleProducts: [
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
    ],
  },
  flashSaleIsComing: {
    id: "be618c75-0216-4320-b09d-1817d5272887",
    name: "r4a",
    startDate: "2099-04-18T16:00:00",
    endDate: "2999-04-18T16:00:00",
    flashSaleProducts: [
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Phở tái đặc biệt",
            thumbnail: defaultFlashSaleProductImg,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
    ],
  },
};

export const storeDiscountDefaultLocal = [
  {
    id: "14350b34-6cdd-4186-93b0-b02d00aa2dc1",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Giảm 10%",
    code: "GQJNTH",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 10,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
  {
    id: "482e0dc3-9f55-4872-8db4-d6a5bf8bd1e2",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Giảm 20%",
    code: "K8IU01",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 20,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
  {
    id: "5dae3343-7e54-40d5-8a12-8d6d85347488",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Giảm 30%",
    code: "AB2RF3",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 30,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
  {
    id: "e4f37b0c-3690-4fa1-9243-cce5da0cb560",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Giảm 40%",
    code: "ZL28RR",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 40,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
  {
    id: "af6cb100-fdd1-4754-a127-d303f4f3bc2b",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Giảm 50%",
    code: "K6YXBV",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 50,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
  {
    id: "399b954b-f344-454e-b459-14ee76c714b4",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Giảm 5%",
    code: "UOTNQ2",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 5,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
  {
    id: "ae1f00dd-318c-4968-9848-1d57f666478e",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Giảm 60%",
    code: "ECDBDB",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 60,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
];

export const discountCodeDefaultLocal = [
  {
    id: "14350b34-6cdd-4186-93b0-b02d00aa2dc2",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Voucher Giảm 10%",
    code: "GQJNTH",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 10,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
  {
    code: "482e0dc3-9f55-4872-8db4-d6a5bf8bd1e3",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Voucher Giảm 20%",
    code: "K8IU01",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 20,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
  {
    code: "5dae3343-7e54-40d5-8a12-8d6d85347482",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Voucher Giảm 30%",
    code: "AB2RF3",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 30,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
  {
    code: "e4f37b0c-3690-4fa1-9243-cce5da0cb562",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Voucher Giảm 40%",
    code: "ZL28RR",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 40,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
  {
    code: "af6cb100-fdd1-4754-a127-d303f4f3bc22",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Voucher Giảm 50%",
    code: "K6YXBV",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 50,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
  {
    code: "399b954b-f344-454e-b459-14ee76c714b2",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Voucher Giảm 5%",
    code: "UOTNQ2",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 5,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
  {
    code: "ae1f00dd-318c-4968-9848-1d57f6664782",
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    name: "Voucher Giảm 60%",
    code: "ECDBDB",
    discountCodeTypeId: 0,
    isPercentDiscount: true,
    percentNumber: 60,
    maximumDiscountAmount: 0,
    startDate: "2023-06-26T03:20:00",
    endDate: "2023-06-26T20:00:00",
  },
];

export const noNotificationHomePageVi = {
  noNotifications: "Chưa Có Thông Báo",
  noNotificationsRightNow: "Hiện tại bạn chưa có thông báo mới.",
  comeBackLater: "Vui lòng quay lại sau.",
};
